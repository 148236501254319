import baseComponent from '@/scripts/baseComponent'
import lazyLoadComponent from '@/scripts/lazyLoadComponent'
import SkeletonBox from '@/components/SkeletonBox'
import { API_CALL_STATUS } from '@/constant/config'
import fixedRentalApi from '@/api/gpuHub/fixedRental' 
import { mapState } from 'vuex'
import EventBus from '@/event/EventBus' 
import ColumnUser from '@/views/components/column/User.vue'
import ColumnBalance from '@/views/components/column/Balance.vue'
import CaretTop from '@/views/components/Icon/CaretTop.vue'
import CaretBottom from '@/views/components/Icon/CaretBottom.vue'
const queryString = require('query-string')

export default {
    extends: baseComponent,

    components: {
        ColumnUser,
        ColumnBalance,
        CaretTop,
        CaretBottom,
        detailUser: lazyLoadComponent({
            componentFactory: () => import('@/views/users/DetailUserInfo'),
            loading: SkeletonBox
        })
    },

    data() {
        return {
            isCheckAll: false,
            listData: [],
            pagination: {
                status: 1,
                querySearch: '',
                orderBy: 'A.END_TIME',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 20,
                outRowsNumber: 0,
                ignoreExpired: false
            }
        }
    },

    computed: {
        ...mapState({
            listStaff: (state) => state.staffs,
            listServicePack: (state) => state.common.listServicePack,
            decentralization: (state) => state.auth.decentralization
        })
    },
    created() {
        EventBus.$on('fixed-rental-perform-action', this.performAction)
        let localIgnoreExpired = localStorage.getItem('ignoreExpiredFixedRental')
        let ignoreExpired = (localIgnoreExpired && localIgnoreExpired === 'true') || false
        this.pagination.ignoreExpired = ignoreExpired
        localStorage.setItem('ignoreExpiredFixedRental', this.pagination.ignoreExpired)
        this.getData()
    },
    methods: {
        openPopup(popup) {
            switch (popup.type) {
                case 'fixed-rental-create-new': 
                case 'fixed-rental-details':
					EventBus.$emit('openPopup', popup)
                    break;
				case 'fixed-rental-machine-deactivate':
					$(`#${popup.type}`).modal('show');
					break;
            }
        },

        // Selected all item
        selectedAll() {
            this.listData.forEach((element) => {
                element.isChecked = this.isCheckAll
            })
        },

        // Selected item
        selectedItem() {
            if (this.listData.filter((x) => x.isChecked).length === this.listData.length) {
                this.isCheckAll = true
            } else {
                this.isCheckAll = false
            }
        },

        //Context menu action
        contextMenuAction(command) {
            console.log(command)
            switch (command.type) {
                case 'fixed-rental-edit':
                    // EventBus.$emit("openPopup", command);
                    break
                case 'fixed-rental-deactivate':
                    //EventBus.$emit('openPopup', command)
					this.openPopup({ type: 'fixed-rental-machine-deactivate' })
                    break
            }
        },

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData()
                    break
                case 'refresh-silent':
                    this.getData(param.action)
                    break
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data)
                    break
            }
        },

        ignoreExpiredChanged() {
            localStorage.setItem('ignoreExpiredFixedRental', this.pagination.ignoreExpired)
            this.getData()
        },

        // Get data
        getData(type) {
            this.isCheckAll = false
            switch (type) {
                case 'refresh-silent':
                    break
                default:
                    this.feedStatusLoading(API_CALL_STATUS.LOADING)
                    break
            }
            fixedRentalApi
                .getFixedRental(queryString.stringify(this.pagination))
                .then((res) => {
                    try {
                        let responseData = res.data
                        let pagingItem = res.data.pagingItem
                        responseData.data.forEach((element) => {
                            element.columnUser = this.mapColumnUser(element)
                            element.machineInfo = JSON.parse(element.machineInfo)
                        })
                        this.listData = responseData.data
                        this.pagination.orderBy = pagingItem.orderBy
                        this.pagination.directionSort = pagingItem.directionSort
                        this.pagination.pageIndex = pagingItem.pageIndex
                        this.pagination.pageSize = pagingItem.pageSize
                        this.pagination.outRowsNumber = pagingItem.outRowsNumber
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS)
                    } catch (error) {
                        console.log('getData -> error', error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch((error) => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    // location.href = "/login";
                                }, 2000)
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log('getData -> error', error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break
                            }
                        }
                    } catch (error) {
                        console.log('getData -> error', error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED)
                })
        },

        // Search list
        searchList() {
            //this.pagination.status = 0;
            this.pagination.querySearch
            this.getData()
        },

        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1
            this.pagination.orderBy = element
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData()
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1
            this.pagination.pageSize = pageSize
            this.getData()
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element
            this.getData()
        },

        // Map to component
        mapColumnUser(element) {
            let result = {}
            try {
                result = {
                    userId: element.userId,
                    username: element.username,
                    registerAt: element.userCreatedAt,
                    region: element.region
                }
            } catch {
                result = {}
            }
            return result
        },

        mapColumnBalance(element) {
            let result = {}
            try {
                result = {
                    username: element.username,
                    totalRecharge: element.totalRecharge,
                    balance: element.balance,
                    totalImage: element.totalImage,
                    imageThreshold: element.imageThreshold,
                    totalStorage: element.totalStorage,
                    storageThreshold: element.storageThreshold
                }
            } catch {
                result = {}
            }
            return result
        },
        getStarffCare(element) {
            let staffItem = this.listStaff.find((x) => x.email === element.staffCare)
            return staffItem !== undefined ? staffItem.fullName : ''
        },
        getModifiedByText(element) {
            return element.lastModifiedBy === 'Fixed rental service' ? 'Automatically by service' : element.lastModifiedBy.includes('@irender.vn') ? 'Manual by admin' : 'Manual by user'
        },
        getCommandParamActiveDeactive(element) {
            let avtMoreThanZero = {
                type: 'fixed-rental-deactivate',
                user: element
            }
            let avtEqualZero = {
                type: 'fixed-rental-activate',
                user: element
            }
            return element.available > 0 ? avtMoreThanZero : avtEqualZero
        },
        getCommandActiveDeactiveColor(element) {
            let color = element.available > 0 ? 'red' : 'forestgreen'
            return color
        }
    }
}
